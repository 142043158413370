import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
//
import {
  Login,
  HomePage,
  // Vilket avsnitt?
  ShowsList,
  ShowsSearch,
  SingleShow,
  SingleEpisode,
  ListTranscriptions
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Full layout
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'shows/my', element: <ShowsList /> },
        { path: 'shows/search', element: <ShowsSearch /> },
        { path: 'shows/:id', element: <SingleShow /> },
        { path: 'episodes/:id', element: <SingleEpisode /> },
        { path: 'transcriptions', element: <ListTranscriptions /> },
      ]
    },
    // Simple layout
    {
      element: <SimpleLayout />,
      children: [
        {
          path: 'auth',
          children: [
            { path: 'login', element: <Login /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
