import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Vilket avsnitt?
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const Login = Loadable(lazy(() => import('../pages/auth/Login')));

export const ShowsList = Loadable(lazy(() => import('../pages/shows/List')));
export const ShowsSearch = Loadable(lazy(() => import('../pages/shows/Search')));
export const SingleShow = Loadable(lazy(() => import('../pages/shows/Single')));

export const SingleEpisode = Loadable(lazy(() => import('../pages/episodes/Single')));

export const ListTranscriptions = Loadable(lazy(() => import('../pages/transcriptions/List')));

