import React, { createContext, useState, useMemo, useEffect } from 'react';
import { GetCurrentUser } from 'src/api/vaAPI';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = async () => {
        const userData = await GetCurrentUser();
        setUser(userData);
        setIsAuthenticated(userData != null);
    };

    const logout = () => {
        setUser(null);
    localStorage.removeItem('vaAccessToken');
    setIsAuthenticated(false);
};

const authContextValue = useMemo(
    () => ({
        user,
        isAuthenticated,
        login,
        logout,
    }),
    [user, isAuthenticated]
);

useEffect(() => {
    const token = localStorage.getItem('vaAccessToken');

    if (token) {
        login();
    }
}, []);

return (
    <AuthContext.Provider value={authContextValue}>
        {children}
    </AuthContext.Provider>
);
};
