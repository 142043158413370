// Import axios
import axios from 'axios';

// Get the access token from the local storage
let accessToken = null;
if (typeof window !== 'undefined') {
    accessToken = localStorage.getItem("vaAccessToken");
}

const vaAPI = axios.create({
    baseURL: 'http://localhost:5310/api/',
    headers: {
        'Authorization': `Bearer ${accessToken}`
    }
});

export const GetCurrentUser = async () => {
    console.log("Token: ", accessToken);
    if (accessToken == null) return null;
    const response = await vaAPI.get('/users/me');
    console.log("Response: ", response.data);
    return response.data;
};

export default vaAPI;